




































































































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch }                          from 'vue-property-decorator'
import { mapGetters, mapState }                                           from 'vuex'
import { Ability }                                              from '@/types/Ability'
import { EnsembleInterface, TypeEnsemble } from '@/types/Ensemble'
import { FontAwesomeIcon }                                      from '@fortawesome/vue-fontawesome'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditAffectationEquipe                               from '@/components/AffectationExaminateurs/PopupEditAffectationEquipe.vue'
import { formatDate, getError, checkIcone }                   from '@/utils/helpers'
import ErrorDisplay                                             from '@/components/ErrorDisplay.vue'

import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'



@Component({
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        ExaGenericTable,
        PopupEditAffectationEquipe,
        ErrorDisplay,
        PopUpInfoIntervenant
    },
    computed: {
        ...mapGetters('ensemble', ['loading', 'error', 'ensembles', 'meta', 'links', 'totalRows']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapGetters('session', ['sessionActive']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('affectationEquipe', ['GET_LISTE_EQUIPE', 'GET_LOADING', 'GET_META']),
        ...mapGetters('affectationEquipe', ['GET_ERROR'])
    }
})
export default class AffectationEquipes extends Vue {
    @Watch('GET_LOADING')
    isLoading() {
        this.tableLoading   = this.$store.getters['affectationEquipe/GET_LOADING']
    }


    @Watch('GET_LISTE_EQUIPE')
    updateListeEquipe() {
        this.listeEquipes   = this.$store.getters['affectationEquipe/GET_LISTE_EQUIPE']
        this.setDataForGenericTab(this.listeEquipes)
    }

    @Watch('ensembles')
    updateEnsemblesData() {
        this.ensemblesData = this.$store.getters['ensemble/ensembles']
        this.calculEnsembles()
    }

    @Watch('GET_META')
    watchMeta() {
        if (this.$store.getters['affectationEquipe/GET_META']) {
            if (this.totalEquipe !== this.$store.state.affectationEquipe.meta.total) {
                this.totalEquipe = this.$store.state.affectationEquipe.meta
            }
        } else {
            this.totalEquipe = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    @Watch('sessionActive', { deep: true })
    wSessionActive () {
        if (this.$store.state.session.sessionActive) {
            this.sessionActiveTmp = this.$store.state.session.sessionActive
        }
    }

    session_id              = 0
    filtres: any            = []
    listeEquipes: any       = []
    tableLoading            = false
    header_loaded           = false
    sortBy                  = ''
    sortDesc                = false
    sortDirection           = 'asc'
    filter                  = ''
    filterOn                = []
    ensemble: any           = null
    ensembleTemp: any       = null
    Ability                 = Ability
    TypeEnsemble            = TypeEnsemble
    totalEnsembles          = 0
    ensemblesSansCentreId   = 0
    dataForTab: Array<any>  = []
    lastPage                = 1
    selectedCentre          = null
    errorMsg                = null
    formatDate              = formatDate
    totalEquipe             = 0
    modalValidationEquipesParCentre     = false
    modalInvalidationEquipesParCentre   = false
    sessionActiveTmp: any = []
    validation_possible = false

    ensemblesData: Array<EnsembleInterface>   = []
    // gestion affichage popupEditEquipe
    showModalEditionCentre?: boolean          = false
    // toggle popup qui remplace edition centre si les ensembles non valide
    showEnsemblesNotValidated                 = false
    // toggle popup qui remplace edition centre si équipes deja validés
    showCentresAlreadyValidated               = false


    showModalInfoIntervenant = false
    user_id_temp = 0


    /**
     * Initialisation des données affichées dans le tableau ainsi que les compteurs
     */
    initDatas (listeEquipes: any)
    {
        if (listeEquipes)
        {
            this.setDataForGenericTab(listeEquipes)
        }
    }


    /**
     * Récupération sur le serveur des 100 premières équipes s'ils ne sont pas déja
     * présents dans le state
     */
    loadEquipeIfNotExists ()
    {
        return new Promise((resolve) =>
        {
            if (this.$store.getters['affectationEquipes/GET_LISTE_EQUIPE'].length === 0)
            {
                this.$store.dispatch('affectationEquipes/GET_LISTE_EQUIPE').then((result) =>
                {
                    resolve(result)
                }).catch((error) => {
                    console.log('ko:' + error)
                })
            }
            else
            {
                resolve(true)
            }
        })
    }


    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        const validated = this.$store.getters['session/sessionSelect'].ensembles_centres_validated_at
        const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
        const icone = checkIcone(Ability.ORAL_AFEX_MANAGE, can, validated)
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const centreOral                                = result.name ? result.name : '-'
                const ensembles_examinateurs_sum_nb_estime      = result.ensembles_examinateurs_sum_nb_estime ? result.ensembles_examinateurs_sum_nb_estime : '-'
                const ensembles_examinateurs_tp_sum_nb_estime   = result.ensembles_examinateurs_tp_sum_nb_estime ? result.ensembles_examinateurs_tp_sum_nb_estime : '-'
                const gestionnaire                              = result.gestionnaire && result.gestionnaire.name ? result.gestionnaire.name + ' ' + result.gestionnaire.first_name : '-'
                const affectationEquipes                        = this.trieAffectationsEquipe(result)

                const line = [
                    { label: icone.label,   item: result.id,                                    type: 'action',  typeAction: 'edit',        class: 'commons_first_action_button', icon:icone.icon },
                    { label: '',            item: centreOral,                                   type: 'text',    typeAction: null,          class: '' },
                    { label: '',            item: result.gestionnaire ? result.gestionnaire.id : 0, type: 'actionText', typeAction: 'infoIntervenant', class: 'text-info item_action', text: gestionnaire },
                    { label: '',            item: ensembles_examinateurs_sum_nb_estime,         type: 'text',    typeAction: null,          class: 'text-center col-min-width' },
                    { label: '',            item: ensembles_examinateurs_tp_sum_nb_estime,      type: 'text',    typeAction: null,          class: 'text-center col-min-width' },
                    { label: '',            item: affectationEquipes,                           type: 'text',    typeAction: null,          class: '' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    genericfields = [
        { key: 'etatEdit',                                  label: '',                                  sortable: false,    class: '', type: 'action' },
        { key: 'name',                                      label: 'Centre d\'oral',                    sortable: true,     class: 'text-start', type: 'text' },
        { key: 'gestionnaire.name',                         label: 'Chef de centre',                    sortable: true,     class: 'text-start', type: 'text' },
        { key: 'ensembles_examinateurs_sum_nb_estime',      label: 'Examinateurs',                      sortable: true,     class: 'text-center', type: 'text' },
        { key: 'ensembles_examinateurs_tp_sum_nb_estime',   label: 'Examinateurs TP',                   sortable: true,     class: 'text-center', type: 'text' },
        { key: 'affectationEquipes',                        label: 'Affectation des équipes',           sortable: false,    class: 'text-start', type: 'text' }
    ]

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab()
    {
        // Si jamais changement d'avis sur le filtre, pour passer le filtre
        /*         const matieres = this.$store.getters['matiere/matieres']
        const filieres = this.$store.getters['concour/banques']
        const filtre_affec_equipes = []

        for (const f in filieres) {
            filtre_affec_equipes.push({ index: filieres[f].id, name: filieres[f].name })
        }
        for (const m in matieres) {
            console.log(matieres[m])
            filtre_affec_equipes.push({ index: matieres[m].id, name: matieres[m].name })
        } */

        const loading = this.$store.state.affectationEquipe.loading

        /*   for (const f in this.filtrePourAffectationEquipes){
                filtre_affec_equipes.push[]
        } */
        this.filtres =
        [
            { libelle: 'Centre',     defautOptionlibelle: 'Rechercher un',   model: 'name',                     value: '',  index: 'name',  datas: '',                          loading: loading, options: { type: 'form', fieldsKey: 'name' } },
            { libelle: 'Nom',        defautOptionlibelle: 'Rechercher un',   model: 'gestionnaire.name',        value: '',  index: 'gestionnaire.name',         datas: '',      loading: loading, options: { type: 'form', fieldsKey: 'gestionnaire.name' } },
            { libelle: 'Ensembles',  defautOptionlibelle: 'Rechercher un',   model: 'ensembles.name',           value: '',  index: 'affectationEquipes',        datas: '',      loading: loading, options: { type: 'form', fieldsKey: 'affectationEquipes' } }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'edit':
                    this.selectedCentre = this.listeEquipes.filter((equipe: any) => equipe.id === paParams[1])[0]
                    this.editEquipe(this.selectedCentre)
                    break
                case 'sortHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'filterHandler':
                    this.loadHandler(paParams[1])
                    break

                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break

                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break

                default:
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp  = user_id
        this.showModalInfoIntervenant = true
    }

    /** soumet la validation des équipes  */
    validationEquipes(close: Function) {
        // toast
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        // params ensembles_centres_validate = 1 pour valider
        const params = {
            session_id: this.session_id,
            ensembles_centres_validate: 1
        }
        // dispatch et recup des infos de la session actuelle
        this.$store.dispatch('affectationEquipe/validationAffectationDesEquipesAuxCentres', params)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                await this.$store.dispatch('session/getSession', { session_id : this.$store.getters['auth/user_session_id'] })
                this.$bvToast.toast('Enregistrement des équipes terminé !', succesToaster)
                this.load()
                close()
            })
            .catch((Err) => {
                this.errorMsg = getError(Err)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** soumet l'invalidation des équipes  */
    invalidationEquipes() {
        // Toast
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)
        // params : ensembles_centres_validate = 0 pour invalider
        const params = {
            session_id: this.session_id,
            ensembles_centres_validate: 0
        }
        // dispatch avec params
        this.$store.dispatch('affectationEquipe/validationAffectationDesEquipesAuxCentres', params)
            .then(async () => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                // recup reponse dans openSession
                await this.$store.dispatch('session/getSession', { session_id : this.$store.getters['auth/user_session_id'] })
                this.$bvToast.toast('Invalidation des équipes terminée !', succesToaster)
                this.closeInvalidate()
                this.load()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }


    /** calcule le total des ensembles et le nombre d'esemble sans centre_id       */
    calculEnsembles() {
        this.totalEnsembles         = 0
        this.ensemblesSansCentreId  = 0
        this.ensemblesData.forEach(element => {
            this.totalEnsembles += 1
            if (element.centre_id !== null) {
                this.ensemblesSansCentreId += 1
            }
        })
    }

    /**
     * Appel d'une nouvelle page de Centres lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.dispatch('affectationEquipe/getAffectationEquipes', params).then((resp) => {
            this.setDataForGenericTab(resp.data.data)
        })
    }

    // constante utilisée pour créer le filtre des affectations
    // filtrePourAffectationEquipes = {}
    /** Fonction de création pour l'affichage des informations de la colonne Affectation des équipes, création d'un objet, puis transormation en string d'affichage ex: PC(19,20); PSI(27,28)
  */
    trieAffectationsEquipe(line: any) {
        // declaration variables
        const obj: any      = {}
        let affichage       = ''

        // dans chaque ligne de la table ...
        line.ensembles.forEach((element: any) => {
            // ... controle le type_ensemble ( ici == 1)...
            if (element && element.type_ensemble && element.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                // ... création ou recupération d'un objet avec comme clé le code du concours
                const key = element.concour.code
                if (element.name) {
                    // si la clé existe deja, ajout des element.name et d'une virgule
                    if (obj[key] && obj[key] !== undefined) {
                        obj[key] += ',' + element.name
                    } else {
                        obj[key] = element.name
                    }
                }
            }
            // cas numéro deux, type_ensemble = 0, on ne récupère que name
            // eslint-disable-next-line no-mixed-operators
            if (element.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP || element.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
                const key = element.name

                obj[key] = ''
            }
        })
        // this.filtrePourAffectationEquipes = obj
        // transformation de l'objet en une string
        for (const iterator in obj) {
            let string = ''
            // si la var affichage a déja des informations : un séparateur ";" est mis.
            if (affichage !== '') {
                affichage += '; '
            }
            // dans le cas numero deux ci-dessus, on ne souhaite afficher que la key de l'objet
            if (obj[iterator] === '') {
                string += iterator
            } else {
                // cas un dans ce cas des parentheses sont mises.
                string += iterator + '( ' +  obj[iterator] + ' )'
            }
            affichage += string
        }
        return affichage || '-'
    }

    /**
     * Fermeture de la modale d'édition Equipe
     */
    reinitShowModalCentre (e: any)
    {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.ensemblesData = e
        this.showModalEditionCentre = false
    }

    initShowModalCentre () {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.showModalEditionCentre = true
    }

    /** popup d'alerte : ensemble non validé  */
    initShowEnsemblesNotValidated() {
        this.showEnsemblesNotValidated = true
    }

    /** fermeture popup d'alerte : ensemble non validé  */
    cancelEnsemblesNotValidated() {
        this.showEnsemblesNotValidated = false
    }

    initShowCentresAlreadyValidated()
    {
        this.showCentresAlreadyValidated = true
    }

    cancelShowCentresAlreadyValidated()
    {
        this.showCentresAlreadyValidated = false
    }

    /** affichage modale validation des affectectations */
    validate () {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.modalValidationEquipesParCentre = true
    }

    /** fermeture modale validation des affectectations */
    closeValidate () {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.modalValidationEquipesParCentre = false
    }

    invalidate () {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.modalInvalidationEquipesParCentre = true
    }

    closeInvalidate() {
        this.$store.commit('affectationEquipe/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.modalInvalidationEquipesParCentre = false
    }

    /** Affichage popup edition, contrôle que l'onglet gestion des equipes est validé et que affectation des équipes ne l'est pas, sinon message informatif  */
    editEquipe(equipe: any) {
        if (this.$store.state.session.sessionSelect.ensembles_validated_at !== null && this.$store.state.session.sessionSelect.ensembles_centres_validated_at === null)
        {
            this.selectedCentre = equipe
            this.initShowModalCentre()
        } else if (this.$store.state.session.sessionSelect.ensembles_validated_at === null && this.$store.state.session.sessionSelect.ensembles_centres_validated_at === null)
        {
            this.initShowEnsemblesNotValidated()
        } else if (this.$store.state.session.sessionSelect.ensembles_validated_at !== null && this.$store.state.session.sessionSelect.ensembles_centres_validated_at !== null)
        {
            this.selectedCentre = equipe
            this.initShowModalCentre()
        }
    }

    load() {
        const params = {
            perPage: 10000
        }

        this.$store.dispatch('session/getSession', { session_id: this.$store.state.auth.user_session_id }).then(() => {
            this.sessionActiveTmp = this.$store.state.session.sessionSelect
            this.session_id = this.$store.state.session.sessionSelect.id
        })



        this.$store.dispatch('affectationEquipe/getAffectationEquipes').then(() => {
            this.listeEquipes = this.$store.getters['affectationEquipe/GET_LISTE_EQUIPE']
            this.setDataForGenericTab(this.listeEquipes)
            this.setFiltersForGenericTab()

            // Validation / Invalidation uniquement dans le contexte des oraux
            if (this.$route.path === '/affectation_examinateurs') {
                this.validation_possible = true
            }
        })
        // recup ensemble
        this.$store.dispatch('ensemble/getEnsembles', { params: params }).then((response) => {
            this.ensemblesData          = response.data.data
            this.calculEnsembles()
            this.header_loaded = true
        }).catch((error) => {
            console.log('ko:' + error)
        })
    }

    mounted () {
        this.load()
    }
}
