







































import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupPlanningPDF             from '@/components/Plannings/PopupPlanningPDF.vue'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay,
        PopupPlanningPDF
    },
    computed: {
        ...mapState('candidat', ['candidats', 'totalRows', 'lastPage', 'total', 'loading', 'currentPage', 'error']),
        ...mapGetters('examinateur', ['examinateur_select']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class ConsultationExaminateur extends Vue {
    Ability = Ability
    genericfields = [
        { key: 'serie.name',    label: 'Série',         sortable: true,    class: 'text-start',  type: 'text' },
        { key: 'ordrepassage',  label: 'Date',          sortable: true,    class: 'text-start',  type: 'text' },
        { key: 'code',          label: 'Code',          sortable: true,    class: 'text-start',  type: 'text' },
        { key: 'name',          label: 'Candidat',      sortable: true,    class: 'text-start',  type: 'text' },
        { key: 'amenagement',   label: 'Aménagements',  sortable: false,   class: 'text-start',  type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;
    defaultFetchParams = { page: 1, sort: 'ordrepassage', direction: 'asc' }

    filtres:    any         = []
    dataForTab: Array<any>  = []
    exportWorking = false

    @Watch('candidats')
    watchTableau() {
        const data = this.$store.state.candidat.candidats
        this.setDataForGenericTab(data)
    }

    /** Alimentation des lignes du tableau */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                let amenagements = ''

                for (const a in result.amenagementsTypeOral) {
                    amenagements += '* ' + result.amenagementsTypeOral[a].name + '<br/>'
                }


                const line = [
                    { label: 'serie',       item: result.serie.name,                      type: 'text', typeAction: null, class: 'text-start min_width_col' },
                    { label: 'ordrepassage', item: formatDateSinTime(result.seances[0].creneau.jour) + ' à ' + formatDateHoursMinutes(result.seances[0].creneau.h_debut), type: 'text', typeAction: null, class: 'text-start min_width_col' },
                    { label: 'code',        item: result.code,                            type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'name',        item: result.name + ' ' + result.first_name,  type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'amenagement', item: amenagements,                           type: 'html', typeAction: null, class: 'text-start' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler(paParams[1].page)
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de séances lors du scroll
     */
    loadHandler (params: any)
    {
        this.$store.dispatch('candidat/getMoreCandidatsAmenagements', params)
    }

    /**
     * Appel des datas avec un sort en paramètres
     */
    filtreSortHandler (params: any)
    {
        if (JSON.stringify(this.defaultFetchParams) !== JSON.stringify(params)) {
            this.defaultFetchParams = JSON.parse(JSON.stringify(params))
            this.$store.dispatch('candidat/getCandidatsAmenagements', params).then(() => {
                this.setDataForGenericTab(this.$store.state.candidat.candidats)
            })
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setFiltersForGenericTab() {
        this.filtres = [
            { libelle: 'Code', defautOptionlibelle: 'Rechercher un',   model: 'code', value: '', index: 'code', datas: this.$store.state.candidat.code, loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'code', strict: true } },
            { libelle: 'Nom', defautOptionlibelle: 'Rechercher un',   model: 'name', value: '', index: 'name', datas: this.$store.state.candidat.name, loading: this.$store.state.candidat.loading, options: { type: 'form', fieldsKey: 'name' } }
        ]
    }

    /**
     * Exporter le planning au format excel
     */
    export_excel () {
        this.$store.commit('candidat/SET_ERROR', null)
        let fileName = ''
        this.exportWorking = true

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Export en cours...', infosToaster)

        this.$store.dispatch('candidat/getExportCandidatsAmenagements')
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers) || 'Candidats_amenagements.xlsx'
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
                this.exportWorking = false
            })
    }

    // Chargement des candidats
    loadCandidats () {
        const params = {}
        Vue.set(params, 'page', '1')
        Vue.set(params, 'sort', 'ordrepassage')
        Vue.set(params, 'direction', 'asc')
        this.$store.dispatch('candidat/getCandidatsAmenagements', params).then(() => {
            this.setDataForGenericTab(this.$store.state.candidat.candidats)
            this.setFiltersForGenericTab()
        })
    }

    beforeMount () {
        this.$store.dispatch('matiere/getMatieres').then(() => {
            this.$store.dispatch('concour/getConcoursActifs').then(() => {
                this.loadCandidats()
            })
        })
    }
}
