

























import { Vue, Component, Watch } from 'vue-property-decorator'
import { Ability } from '@/types/Ability'
import AffectationEquipes from '@/views/AffectationExaminateurs/AffectationEquipes.vue'
import AffectationsExaminateursParEquipe from '@/views/AffectationExaminateurs/AffectationsExaminateursParEquipe.vue'
import { mapGetters, mapState } from 'vuex'


@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id'])
    },
    components: {
        AffectationEquipes,
        AffectationsExaminateursParEquipe
    }
})
export default class AffectationExaminateurs extends Vue {
    Ability = Ability
    tabSelected = 'AffectationsExaminateursParEquipe'

    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    load() {
        if (this.$store.state.matiere.matieres.length === 0) {
            this.$store.dispatch('matiere/getMatieres')
        }
    }

    mounted () {
        this.load()
    }
}
