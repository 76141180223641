







































































































import { Watch, Prop, Vue, Component }                              from 'vue-property-decorator'
import { mapGetters }                                     from 'vuex'
import { TypeEnsemble }     from '@/types/Ensemble'
import { Ability }                                                  from '@/types/Ability'
import  ErrorDisplay                                                from '@/components/ErrorDisplay.vue'



/**
 * Composant principal de la pop up d'édition de candidats
 */
@Component({
    computed: {
        ...mapGetters('etablissement', ['getEtablissementById']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA']),
        ...mapGetters('affectationEquipe', ['GET_ERROR'])
    },
    components: {
        ErrorDisplay
    }
})
export default class PopupEditAffectationEquipe extends Vue {
    @Prop() showEdit?: boolean;
    @Prop() centre?: any
    @Prop() ensemblesData: any
    @Prop() sessionActive: any

    @Watch('showEdit', { immediate: true, deep: true })
    showEditWatch() {
        if (this.showEdit && this.centre) {
            this.getEnsemblesData()
            this.ensemblesDataCopy      = JSON.parse(JSON.stringify(this.ensemblesData))
            this.centreCopy             = JSON.parse(JSON.stringify(this.centre))
            this.selection              = this.selectIndex(this.centreCopy)
            this.selectionTemps         = JSON.parse(JSON.stringify(this.selection))
            this.nbExam                 = this.totalUpdate(this.ensemblesDataCopy, this.selection)
            this.findExaminateurs(this.selection, this.ensemblesDataCopy)
        }
    }

    watchSelected(selection: any) {
        this.updateSessionIdPourMenuDeroulant(this.ensemblesDataCopy, this.selectionTemps, this.selection)
        this.nbExam                 = this.totalUpdate(this.ensemblesDataCopy, selection)
        this.selectionTemps         = JSON.parse(JSON.stringify(this.selection))
        this.findExaminateurs(this.selection, this.ensemblesDataCopy)
    }

    // Variables
    nbExam                      = {}
    selectedEnsemble            = []
    selection: any              = []
    selectionTemps: any         = []
    centreCopy: any             = []
    ensemblesDataCopy: any      = []
    TypeEnsemble                = TypeEnsemble
    Ability                     = Ability
    showError                   = false



    closeModal () {
        this.selectedEnsemble    = []
        this.selection           = []
        this.centreCopy          = []
        this.selectionTemps      = []
        this.$emit('closeModal', this.ensemblesDataCopy)
        this.ensemblesDataCopy   = []
    }


    // recuperation des ensembles s'ils ne sont pas transmis par le parent
    getEnsemblesData() {
        const params = {
            perPage: 10000
        }
        this.$store.dispatch('ensemble/getEnsembles', { params: params }).catch((error) => {
            console.log('ko:' + error)
        })
    }


    affichageExaminateur: any = {}
    /** recherche dans les tableaux des ensembles la correspondance avec l'id et renvois la liste des examinateurs  */
    findExaminateurs(selection: any, ensembles: any) {
        if (ensembles && selection) {
            selection.forEach((element: { id: any }) => {
                const result = ensembles.filter((ensemble: { id: any }) => ensemble.id === element.id)
                if (result && result[0] && result[0].examinateurs) {
                    if (result[0].nb_estime) {
                        result[0].examinateurs.nb_estime = result[0].nb_estime
                    }
                    this.affichageExaminateur[element.id] = result[0].examinateurs
                }
            })
        } else {
            return false
        }
    }

    /** au montage du popup, permet de corréler les ensembles deja connectés au centre et de remplir selected (et donc d'avoir les options affichées correspondante au centre)   */
    selectIndex(centre: any) {
        const index: any[] = []
        if (centre && centre.ensembles) {
            centre.ensembles.forEach((ensemble: any) => {
                const findInEnsembles: any = this.ensemblesDataCopy.filter((index: any) => index.id === ensemble.id)
                const line: any = {
                    id: ensemble.id,
                    nb_estime: ensemble.nb_estime ? ensemble.nb_estime : null
                }
                if (findInEnsembles[0] && findInEnsembles[0].examinateurs) {
                    line.examinateurs = findInEnsembles[0].examinateurs
                }
                index.push(line)
            })
        }
        return index
    }

    /** Enregistrement des Equipes au centre  */
    enregistrerAffectationEquipe() {
        // toast
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Enregistrement en cours ...', infosToaster)

        // Recuperation des ID de chaques equipes
        const selectedEnsembles: any[]  = []
        this.selection.forEach((element: { id: any }) => {
            selectedEnsembles.push(element.id)
        })

        // Construction des paramétres pour le dispatch putAffectationEquipesAuCentre
        const params = {
            centre_id: this.centre.id,
            ensembles: selectedEnsembles
        }
        this.$store.dispatch('affectationEquipe/putAffectationEquipesAuCentre', params)
            .then(() => {
                this.$store.commit('affectationEquipe/UPDATE_ENSEMBLE_DATA_COPY', this.ensemblesDataCopy)
                // Construction des paramétres pour le dispatch getEnsembles perPage 10k = no pagination
                const paramsEnsembles = {
                    perPage: 10000
                }
                this.$store.dispatch('ensemble/getEnsembles', { params: paramsEnsembles }).catch((error) => {
                    console.log('ko:' + error)
                })
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement des équipes dans le centre terminé !', succesToaster)
                this.closeModal()
            })
            .catch((error) => {
                console.log('ko:' + error)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /** Suppression d'une equipe dans la liste  */
    deleteEquipe(id: number, selection: any) {
        if (id === 0 && selection) {
            selection.pop()
        }
        else if (id !== 0 && selection) {
            this.selection = selection.filter((index: any) => index.id !== id)
        }
    }

    /** déclanché au clic sur ajouter, ajoute une ligne dans selection  */
    ajouterEquipe() {
        const line = {
            id: 0
        }
        this.selection.push(line)
    }

    /** update du total des examinateurs et renvois un objet des deux comptages (examinateur et examinateur tp) */
    totalUpdate(ensemblesDataCopy: any[], selection: any) {
        let total       = 0
        let total_tp    = 0
        // Dans la liste séléction (selection est un array des id des équipes affichées)
        selection.forEach((element: { id: number }) => {
            // recherche dans ensemblesDataCopy les correspondances, ajoute au total la longueur du tableau
            if (element && element.id && ensemblesDataCopy) {
                const add = ensemblesDataCopy.find((ensemble: { id: number }) => ensemble.id === element.id)
                if (add && add.nb_estime) {
                    // total
                    if (add.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT || add.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE) {
                        total += add.nb_estime
                    }
                    // total_tp
                    if (add.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
                        total_tp += add.nb_estime
                    }
                }
            }
        })
        return { total: total, total_tp: total_tp }
    }

    /** Afin de rendre le menu déroulant non clicable / non au changement d'équipe par l'utilisateur (params: ensembles / previous etat de selection / prochain etat selection)  */
    updateSessionIdPourMenuDeroulant(ensemblesDataCopy: any, previous: Array<any>, next: Array<any>)
    {
        // previous = sauvegarde des precedents états / next = l'etat actuel
        // si previous existe, recherche dans le tableau la correspondance dans ensemblesDataCopy , s'il trouve, on supprime le centre_id
        // ps: centre_id != null === l'equipe deja dans un centre
        if (previous && this.showEdit) {
            previous.forEach(eachPrevious => {
                if (eachPrevious.id !== 0 && eachPrevious) {
                    ensemblesDataCopy.find((ensemble: { id: number }) => ensemble.id === eachPrevious.id).centre_id = null
                }
            })
        }
        // inverse du if ci-dessus
        if (next && this.showEdit) {
            next.forEach(eachNext => {
                if (eachNext.id !== 0 && eachNext) {
                    ensemblesDataCopy.find((ensemble: { id: number }) => ensemble.id === eachNext.id).centre_id = 999999
                }
            })
        }
    }
}



