





































































































































































































import { mapActions, mapGetters }     from 'vuex'
import { Vue, Component, Prop, Watch }          from 'vue-property-decorator'
import { Decision }                             from '@/types/DossierAcademique'
import { isObject }                             from '@/utils/helpers'
import { Ability }                              from '@/types/Ability'
import _                                        from 'lodash'
import { TypeEnsemble }                         from '@/types/Ensemble'
import { TypePassation }                        from '@/types/Epreuve'
import  ErrorDisplay                            from '@/components/ErrorDisplay.vue'

@Component({
    components: {
        ErrorDisplay
    },
    computed: {
        ...mapGetters('ensembleAffectationsExaminateurs', ['loading', 'examinateurAffectationSelect', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    methods: {
        ...mapActions('postes', ['getAllPostes'])
    }
})

export default class PopupEditAffectationExaminateur extends Vue {
    @Prop() show?: boolean;
    @Prop() infosFiliereEpreuveSelect?: any;

    Ability                                 = Ability
    affectationSelect: any                  = null
    listeIntervenants: any                  = []
    listeIntervenantsSelectionnes: any      = []
    listeIntervenantsNonSelectionnes: any   = []
    listeIntervenantsSuppleants: any        = []
    examinateur_id                          = -1
    remplacant_id                           = null
    error_txt                               = null
    test                                    = ''
    canSave                                 = true
    activeRemplacant?: boolean              = false
    activeSession: any                      = {}
    ensembles_examinateurs_validated_at     = null
    examinateurReadOnlyName                 = ''
    remplacantReadOnlyName                  = ''
    remplacantReadOnlyNameOld               = '' // stocke la valeur d'affichage du remplaçant avant toute modification poste validation. Sera utilisé dans le cadre des scénarios post validation.
    confirmation_modif_post_validation      = false
    remplacantNonSelectionne                = false
    etatsAffectationsOld: any               = null // stocke le tableau des affectations sur les séries pour l'examinateur et remplacant avant toute modification sur l'interface. Sera utilisé dans le cadre des scénarios post validation.
    examinateur_post_validation_modified    = false // stocke l'info si les affectations de l'examinateur a été modifié post validation. Sera utilisé dans le cadre des scénarios post validation.
    remplacant_post_validation_changed      = false // stocke l'info s'il existait un remplaçant et qu'on a changé de remplaçant.
    remplacantIdOld                         = null // stocke l'id éventuel du remplacant avant toute modification sur l'interface. Sera utilisé dans le cadre des scénarios post validation.
    remplacant_post_validation_new          = false // stocke l'info si le remplacant a été modifié post validation. Sera utilisé dans le cadre des scénarios post validation.
    remplacant_post_validation_modified     = false // stocke l'info si les affectations du remplacant ont été modifiées post validation. Sera utilisé dans le cadre des scénarios post validation.
    planificationsLaunched                  = false // TODO - définit si les opérations de plannification ont débuté.
    activeMulti = false

    @Watch('examinateurAffectationSelect')
    majInterface () {
        if (this.$store.state.ensembleAffectationsExaminateurs.examinateurAffectationSelect) {
            this.initDatasAffectationSelect().then(() => {
                this.$store.commit('ensembleAffectationsExaminateurs/SET_LOADING', false) // on stoppe le loading
            })
        } else {
            this.affectationSelect = null
            this.$store.commit('ensembleAffectationsExaminateurs/SET_LOADING', false) // on stoppe le loading
        }
    }

    isObjectLocal (value: any) {
        return isObject(value)
    }


    /**
     * Check si un intervenant est déjà affecté dans une équipe.
     * Pour ça, on vérifie s'il a un ensemble (équipe) et si cet ensemble n'est pas celui sur lequel on est en train de travailler.
     * On part aussi du principe qu'au sein d'un même ensemble (équipe), un intervenant ne sera pas affecté en tant qu'examinateur sur 2 matières différentes.
     */
    shouldDisabled (intervenant: any) {
        let shouldDisabled = false
        if (this.affectationSelect) {
            if (intervenant.ensemble) {
                if (intervenant.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    // cas d'une équipe interclassement
                    // l'intervenant est déjà affecté sur un ensemble
                    if (intervenant.ensemble.name !== this.affectationSelect.ensemble.name) {
                        // l'intervenant est déjà affecté à un ensemble, et il ne s'agit pas de l'ensemble sur lequel on est en train de travailler.
                        shouldDisabled = true
                    }
                } else {
                    // autre cas d'équipes
                    // l'intervenant est déjà affecté sur un ensemble, on vérifie
                    // if (intervenant.ensemble.name === this.affectationSelect.ensemble.name) {
                    // l'intervenant est déjà affecté à un ensemble, et il s'agit de l'ensemble sur lequel on est en train de travailler.
                    // On vérifie s'il s'agit ou non de l'intervenant sélectionné en tant qu'examinateur ou remplaçant pour l'affectation courante
                    shouldDisabled = true
                    for (const indexAffectation in this.affectationSelect.affectations) {
                        if (intervenant.id === this.affectationSelect.affectations[indexAffectation].user_id || intervenant.id === this.affectationSelect.affectations[indexAffectation].remplacant_id) {
                            // intervenant affecté sur l'affectation courante, on ne le disabled pas.
                            shouldDisabled = false
                        }
                    }
                    // }
                }
            }
        }
        return shouldDisabled
    }

    /**
     * Récupère l'ensemble (équipe) de l'intervenant s'il est déjà dans un ensemble différent de l'ensemble sur lequel on travaille
     * et le formatte pour l'affichage dans les listes de choix des intervenants.
     */
    getEquipe (intervenant: any) {
        let intervenantEquipe = ''
        if (this.affectationSelect) {
            if (intervenant.ensemble) {
                if (intervenant.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    // if (intervenant.ensemble.name !== this.affectationSelect.ensemble.name) {
                    // l'intervenant est déjà affecté à un ensemble, et il ne s'agit pas de l'ensemble sur lequel on est en train de travailler.
                    // Dans ce cas, on affiche l'information dans la liste de choix des intervenants.
                    intervenantEquipe = ' (équipe ' + intervenant.ensemble.name + ')'
                    // }
                } else {
                    // autres cas d'équipes
                    // l'intervenant est déjà affecté sur un ensemble, on vérifie
                    // if (intervenant.ensemble.name === this.affectationSelect.ensemble.name) {
                    // l'intervenant est déjà affecté à un ensemble, et il s'agit de l'ensemble sur lequel on est en train de travailler.
                    // On vérifie s'il s'agit ou non de l'intervenant sélectionné en tant qu'examinateur ou remplaçant pour l'affectation courante
                    let shouldDisabled = true
                    for (const indexAffectation in this.affectationSelect.affectations) {
                        if (intervenant.id === this.affectationSelect.affectations[indexAffectation].user_id || intervenant.id === this.affectationSelect.affectations[indexAffectation].remplacant_id) {
                            // intervenant affecté sur l'affectation courante, on ne le disabled pas.
                            shouldDisabled = true
                        }
                    }
                    if (shouldDisabled) {
                        // Dans ce cas, on affiche l'information dans la liste de choix des intervenants.
                        intervenantEquipe = ' (équipe ' + intervenant.ensemble.name + ')'
                    }
                    // }
                }
            }
        }
        return intervenantEquipe
    }

    /**
     * Boucle pour vérifier si les conditions sont réunies pour activer le bouton pour enregistrer la saisie
     */
    updateCanSave () {
        this.canSave = true
        if (this.examinateur_id) {
            if (!this.activeRemplacant) {
                if (this.examinateur_id !== -1) {
                    for (const indexAffectation in this.affectationSelect.affectations) {
                        if (!this.affectationSelect.affectations[indexAffectation].user_id) {
                        // Dans le cas où on ne sélectionne PAS de remplaçant, toutes les affectations doivent avoir le champ user_id renseigné
                            this.canSave = false
                            break
                        }
                    }
                } else {
                    this.canSave = true
                }
            } else {
                if (!this.remplacant_id) {
                    this.canSave = false
                } else {
                    let ExaminateurHasSerie = 0
                    let RemplacantHasSerie = 0
                    for (const indexAffectation in this.affectationSelect.affectations) {
                        if ((this.affectationSelect.affectations[indexAffectation].user_id && this.affectationSelect.affectations[indexAffectation].remplacant_id) || (!this.affectationSelect.affectations[indexAffectation].user_id && !this.affectationSelect.affectations[indexAffectation].remplacant_id)) {
                            this.canSave = false
                            break
                        }
                        if (this.affectationSelect.affectations[indexAffectation].user_id) {
                            ++ExaminateurHasSerie
                        } else {
                            ++RemplacantHasSerie
                        }
                    }
                    if (!this.ensembles_examinateurs_validated_at) {
                        if (ExaminateurHasSerie === 0 || RemplacantHasSerie === 0) {
                            this.canSave = false
                        }
                    } else {
                        // Lorsqu'on est en affectation d'un remplaçant post validation, on permet d'affecter le remplaçant sur l'ensemble des séries.
                        if (RemplacantHasSerie === 0) {
                            this.canSave = false
                        }
                    }
                }
            }
        } else {
            this.canSave = false
        }
    }

    /**
     * Initialisation des différentes données relatives à l'Affectation sélectionnée pour l'édition
     * Permet d'initialiser l'ensemble des éléments de l'interface basés sur l'état de l'affectation existante
     */
    initDatasAffectationSelect () {
        return new Promise((resolve) =>
        {
            this.examinateurReadOnlyName = ''
            this.remplacantReadOnlyName = ''
            this.remplacantReadOnlyNameOld = ''
            this.activeRemplacant = false

            /* ******************************************************************************************************************************************************************************
            // On va travailler et modifier en local une structure identique à l'objet examinateurAffectationSelect du store, mais son utilisation et alimentation locales en données vont différer de ce qui doit être enregistré dans le store.
            // Pour éviter que les modifs locales ne soient répercutées en simultané sur le store, on récupère dans la variable de travail this.affectationSelect un clone de l'objet examinateurAffectationSelect du store
            // L'élément du store concerné par les modfications éventuelles sera par la suite mis à jour par mutation lors de l'enregistrement.
            */
            this.affectationSelect = _.cloneDeep(this.$store.state.ensembleAffectationsExaminateurs.examinateurAffectationSelect)
            // console.log('this.affectationSelect :', this.affectationSelect)
            /* *************************************************************************************************************************************************************************** */

            for (const indexAffectation in this.affectationSelect.affectations) {
                /* **************************************************************************************************************
                Note : pour le fonctionnement de l'interface, on considère qu'une option est cochée si le user_id ou remplacant_id est renseigné pour l'affectation associée à l'option
                Les radio boutons étant exclusifs, à cette étape, on vide le champ user_id si le champ remplacant_id est renseigné (différence donc ici avec les données en store où on doit garder le user_id même si le remplacant_id est renseigné)
                ************************************************************************************************************** */
                if (this.affectationSelect.affectations[indexAffectation].remplacant_id) {
                    this.affectationSelect.affectations[indexAffectation].user_id = null
                }

                // Puis, on check pour récupérer l'examinateur_id et le remplacant_id s'il existe
                if (this.affectationSelect.affectations[indexAffectation].user_id && this.examinateur_id === -1) {
                    this.examinateur_id = this.affectationSelect.affectations[indexAffectation].user_id
                    this.examinateurReadOnlyName = (this.affectationSelect.affectations[indexAffectation].user.civility ? this.affectationSelect.affectations[indexAffectation].user.civility + ' ' : '') + this.affectationSelect.affectations[indexAffectation].user.name + ' ' + this.affectationSelect.affectations[indexAffectation].user.first_name
                }
                if (this.affectationSelect.affectations[indexAffectation].remplacant_id && !this.remplacant_id) {
                    this.remplacant_id = this.affectationSelect.affectations[indexAffectation].remplacant_id
                    this.remplacantReadOnlyName = (this.affectationSelect.affectations[indexAffectation].remplacant.civility ? this.affectationSelect.affectations[indexAffectation].remplacant.civility + ' ' : '') + this.affectationSelect.affectations[indexAffectation].remplacant.name + ' ' + this.affectationSelect.affectations[indexAffectation].remplacant.first_name
                    this.activeRemplacant = true
                }
            }

            // Cas où on a un remplaçant qui est affecté à toutes les séries.
            // Dans ce cas, on n'a pas récupéré l'examinateur originalement affecté, donc on le récupère à la racine de l'objet, sachant que s'il y a un examinateur, le user est forcément rempli
            if (this.affectationSelect.user && this.examinateur_id === -1) {
                this.examinateur_id = this.affectationSelect.user.id
                this.examinateurReadOnlyName = (this.affectationSelect.user.civility ? this.affectationSelect.user.civility + ' ' : '') + this.affectationSelect.user.name + ' ' + this.affectationSelect.user.first_name
            }

            // **************************************************************************************************************
            // on récupère les données de référence (état des affectations et id du remplaçant) avant toute modification sur l'interface. Ces données seront confrontées dans le cas d'une modification post validation.
            this.etatsAffectationsOld = _.cloneDeep(this.affectationSelect.affectations)
            this.remplacantIdOld = this.remplacant_id
            this.remplacantReadOnlyNameOld = this.remplacantReadOnlyName
            // **************************************************************************************************************

            const payload = {
                affectation_examinateur_id: this.$store.state.ensembleAffectationsExaminateurs.examinateurAffectationSelect.id
            }
            this.$store.dispatch('ensembleAffectationsExaminateurs/getListeIntervenants', payload).then(() => {
                this.listeIntervenants = this.$store.state.ensembleAffectationsExaminateurs.listeIntervenants
                this.activeSession = this.$store.state.session.sessionSelect
                this.planificationsLaunched = this.$store.getters['serie/isplanificationsLaunched'] // check si la planification a été lancée
                this.ensembles_examinateurs_validated_at = this.$store.state.session.sessionSelect.ensembles_examinateurs_validated_at
                this.initListesIntervenants()
                this.updateCanSave()
                resolve(true)
            }).catch(() => {
                resolve(true)
            })
        })
    }

    /**
     * Initialisation des différentes listes des intervenants par état de contexte de leurs postes (sélectionnés, suppléants, non sélectionnés)
     * Permet d'alimenter les différents groupes d'options au sein de la liste de choix des intervenants et des remplaçants
     */
    initListesIntervenants () {
        // console.log('this.listeIntervenants :', this.listeIntervenants)
        this.listeIntervenantsSelectionnes = []
        this.listeIntervenantsNonSelectionnes = []
        this.listeIntervenantsSuppleants = []

        if (this.listeIntervenants) {
            for (const indexUser in this.listeIntervenants) {
                /*
                if (this.listeIntervenants[indexUser].name === 'ANNOUSSAMY' || this.listeIntervenants[indexUser].name === 'BERTHOMES') {
                    console.log(this.listeIntervenants[indexUser])
                }
                */
                // On récupère le dossier administratif de l'intervenant pour la session active
                const dossierMatch = this.listeIntervenants[indexUser].dossierAcademiques.filter((dossier: any) => dossier.session_id === this.activeSession.id)
                // console.log('dossierMatch :', dossierMatch)
                if (dossierMatch.length > 0) {
                    const decisionIntervenant = dossierMatch[0].decision_affectation
                    if (dossierMatch[0].decision_affectation === Decision.DECISION_RETENU) {
                        const posteMatch = dossierMatch[0].postes.filter((poste: any) => poste.regroupement === 'Examinateur' && poste.entity_type === 'App\\Models\\Matiere')
                        if (posteMatch.length > 0) {
                            // cas intervenant SUPPLÉANT
                            this.listeIntervenantsSuppleants.push(this.listeIntervenants[indexUser])
                        } else {
                            // cas intervenant SELECTIONNÉ
                            this.listeIntervenantsSelectionnes.push(this.listeIntervenants[indexUser])
                        }
                    } else if (decisionIntervenant === Decision.DECISION_AUCUNE || decisionIntervenant === Decision.DECISION_AJOURNE) {
                        // cas intervenant NON SELECTIONNÉ
                        this.listeIntervenantsNonSelectionnes.push(this.listeIntervenants[indexUser])
                    }
                }
            }
        }
    }

    /** Retourne la liste passée en paramètre ordonnée par name */
    orderedListe (liste: any) {
        return _.orderBy(liste, 'name', 'asc')
    }

    /**
     * Mise à jour des champs user_id et remplacant_id associés au radio bouton cliqué
     */
    updateAffectations (e: any) {
        // Cas de l'examinateur
        if (e.target.id.includes('examinateur_')) {
            if (!this.affectationSelect.affectations[e.target.value].user_id) {
                this.affectationSelect.affectations[e.target.value].user_id = this.examinateur_id
                this.affectationSelect.affectations[e.target.value].remplacant_id = null
            } else {
                this.affectationSelect.affectations[e.target.value].user_id = null
                this.affectationSelect.affectations[e.target.value].remplacant_id = this.remplacant_id
            }
        }

        // Cas du remplaçant
        if (e.target.id.includes('remplacant_')) {
            if (!this.affectationSelect.affectations[e.target.value].remplacant_id) {
                this.affectationSelect.affectations[e.target.value].remplacant_id = this.remplacant_id
                this.affectationSelect.affectations[e.target.value].user_id = null
            } else {
                this.affectationSelect.affectations[e.target.value].remplacant_id = null
                this.affectationSelect.affectations[e.target.value].user_id = this.examinateur_id
            }
        }

        this.updateCanSave()
    }

    /**
     * Mise à jour des champs user_id et remplacant_id de l'ensemble des affectations en fonction de l'intervenant sélectionné
     */
    updateExaminateur (e: any) {
        if (parseInt(e.target.value) !== -1) {
            if (e.target.id.includes('remplacant_')) {
                // S'il s'agit d'un remplaçant, on récupère ses infos d'affichage Civilité Nom Prenom ainsi que son statut sélectionné ou non sélectionné
                this.remplacantReadOnlyName = ''
                this.remplacantNonSelectionne = e.target.options[e.target.options.selectedIndex].id.includes('NonSelectionnes_')
                const intervenantMatch = this.listeIntervenants.filter((intervenant: any) => intervenant.id === parseInt(e.target.value))
                if (intervenantMatch.length > 0) {
                    this.remplacantReadOnlyName = (intervenantMatch[0].civility ? intervenantMatch[0].civility + ' ' : '') + intervenantMatch[0].name + ' ' + intervenantMatch[0].first_name
                }
            }

            if (!this.remplacant_id) {
                for (const indexAffectation in this.affectationSelect.affectations) {
                    this.affectationSelect.affectations[indexAffectation].user_id = this.examinateur_id
                }
            } else {
                // Contexte : on a aussi un remplaçant sélectionné, on doit donc mettre à jour uniquement les options cochées pour la ligne d'options associées à l'examinateur et au remplaçant.
                for (const indexAffectation in this.affectationSelect.affectations) {
                    if (this.affectationSelect.affectations[indexAffectation].user_id) { // On considère qu'une option est cochée si le user_id est renseigné pour l'affectation associée à l'option
                        this.affectationSelect.affectations[indexAffectation].user_id = this.examinateur_id
                    } else if (this.affectationSelect.affectations[indexAffectation].remplacant_id) { // On considère qu'une option est cochée si le remplacant_id est renseigné pour l'affectation associée à l'option
                        this.affectationSelect.affectations[indexAffectation].remplacant_id = this.remplacant_id
                    }
                }
            }

            this.updateCanSave()
        } else {
            // cas du réinit de l'examinateur en "Pas de sélection". Dans ce cas, on réinit aussi le remplaçant éventuel
            // on vide les selects, on décoche tout pour l'examinateur et le remplaçant.
            this.examinateur_id = -1
            this.remplacant_id = null
            this.activeRemplacant = false
            for (const indexAffectation in this.affectationSelect.affectations) {
                this.affectationSelect.affectations[indexAffectation].user_id = null
                this.affectationSelect.affectations[indexAffectation].remplacant_id = null
            }

            this.updateCanSave()
        }
    }

    /**
     * Gère l'activation/désactivation de la sélection d'un remplaçant.
     */
    changeActivationRemplacantValue () {
        if (!this.activeRemplacant) {
            // Si la case d'activation du remplaçant est décochée, on vide le select, on décoche tout pour le remplaçant et on coche tout pour l'examinateur.
            this.remplacant_id = null
            for (const indexAffectation in this.affectationSelect.affectations) {
                this.affectationSelect.affectations[indexAffectation].user_id = this.examinateur_id
                this.affectationSelect.affectations[indexAffectation].remplacant_id = null
            }
        }
        this.updateCanSave()
    }

    /**
     * Gère le scénario pour la demande d'enregistrement des affectations.
     * Si on est post validation, et qu'on détecte un scénario de modification, on envoie sur l'interface d'info+confirmation de modification post validation, sinon on lance directement l'enregistrement.
     */
    gestionSaveAffectations () {
        if (this.ensembles_examinateurs_validated_at) {
            this.examinateur_post_validation_modified = false
            this.remplacant_post_validation_changed = false
            this.remplacant_post_validation_new = false
            this.remplacant_post_validation_modified = false
            let remplacant_id_TEMP = null
            this.confirmation_modif_post_validation = false

            for (const indexAffectation in this.affectationSelect.affectations) {
                if (this.affectationSelect.affectations[indexAffectation].user_id !== this.etatsAffectationsOld[indexAffectation].user_id) {
                    // les séries de l'examinateur ont été modifiées
                    this.examinateur_post_validation_modified = true
                }
                if (this.affectationSelect.affectations[indexAffectation].remplacant_id !== this.etatsAffectationsOld[indexAffectation].remplacant_id) {
                    // les séries du remplaçant ont été modifiées
                    this.remplacant_post_validation_modified = true
                }
                // On récupère l'id du remplaçant si non null dans la série et encore non récupéré
                if (!remplacant_id_TEMP && this.affectationSelect.affectations[indexAffectation].remplacant_id) {
                    remplacant_id_TEMP = this.affectationSelect.affectations[indexAffectation].remplacant_id
                }
            }

            // check si au final l'id du remplaçant a changé
            if (this.remplacantIdOld !== remplacant_id_TEMP) {
                if (this.remplacantIdOld) {
                    // On avait déjà un remplaçant, il a donc été changé
                    this.remplacant_post_validation_changed = true
                } else {
                    // Il n'y avait pas déjà de remplaçant, il s'agit donc d'un ajout de remplaçant.
                    this.remplacant_post_validation_new = true
                }
            }

            // check global pour savoir si on est en mode modification post validation
            if (this.examinateur_post_validation_modified || this.remplacant_post_validation_changed || this.remplacant_post_validation_modified || this.remplacant_post_validation_new) {
                this.confirmation_modif_post_validation = true
            } else {
                // si pas de modification notable, alors on lance l'enregistrement.
                this.saveAffectations()
            }
        } else {
            this.saveAffectations()
        }
    }

    /**
     * Annule la modification de l'affecation post validation.
     */
    annulerModificationPostValidation () {
        this.confirmation_modif_post_validation = false
    }

    /**
     * Confirmation de la validation/invalidation des affectations
     */
    saveAffectations () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const affectationsParams = []

        for (const indexAffectation in this.affectationSelect.affectations) {
            /* **************************************************************************************************************
            Note : au final, le serveur attend quand même que chaque série d'affectations contienne un user_id,
            Donc à cette étape, on force la réinjection de this.examinateur_id pour le champ user_id des séries de l'examinateur

            Donc, on n'utilise pas la ligne d'instruction ci-dessous, mais la seconde.
            affectationsParams.push({ id: this.affectationSelect.affectations[indexAffectation].id, user_id: this.affectationSelect.affectations[indexAffectation].user_id, remplacant_id: this.affectationSelect.affectations[indexAffectation].remplacant_id })
            ************************************************************************************************************** */
            affectationsParams.push({ id: this.affectationSelect.affectations[indexAffectation].id, user_id: this.examinateur_id !== -1 ? this.examinateur_id : null, remplacant_id: this.affectationSelect.affectations[indexAffectation].remplacant_id })
        }

        const params = {
            ensemble_id: this.affectationSelect.ensemble_id,
            affectation_numero: this.affectationSelect.numero,
            payload: {
                force: this.activeMulti ? '1' : '0',
                affectations: affectationsParams
            }
        }

        this.$bvToast.toast('Enregistrement en cours...', infosToaster)
        this.$store.dispatch('ensembleAffectationsExaminateurs/updateAffectationsEnsemble', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast('Enregistrement terminé !', succesToaster)
                this.reset()
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    // Ferme la popup avec réinit des variables locales sensibles.
    reset () {
        this.examinateur_id = -1
        this.remplacant_id = null
        this.examinateurReadOnlyName = ''
        this.remplacantReadOnlyName = ''
        this.affectationSelect = null
        this.confirmation_modif_post_validation = false
        this.remplacantNonSelectionne = false
        this.etatsAffectationsOld = null
        this.$emit('close')
    }

    /**
     * Retourne l'index examinateur selon le type de passation de l'épreuve
     * L'index 1 correspond au Coordonateur dans le cas d'une équipe d'épreuve de TP
     */
    get_index_examinateur () {
        if (this.affectationSelect && this.infosFiliereEpreuveSelect) {
            if (this.infosFiliereEpreuveSelect.typePassation === TypePassation.TYPE_PASSATION_TP && this.affectationSelect.numero === 1) {
                // Coordonateur de TP
                return 'Coordonateur'
            } else {
                return this.affectationSelect.numero
            }
        }
    }
}

