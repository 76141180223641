









































import {  mapGetters, mapState } from 'vuex'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VuePdfApp from 'vue-pdf-app'
import { base64ToArrayBuffer, getFileNameFromHeader } from '@/utils/helpers'
import { Ability } from '@/types/Ability'
import ErrorDisplay from '@/components/ErrorDisplay.vue'

@Component({
    computed: {
        ...mapGetters('auth', [
            'authUser',
            'can',
            'cannot',
            'isA',
            'isNotA'
        ]),
        ...mapState('planning', ['loadingPdf', 'error_plannings']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    },
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        VuePdfApp,
        ErrorDisplay
    }
})
export default class PopupPlanningPDF extends Vue {
    @Prop() showPopup?          : boolean
    @Prop() titre?              : string
    @Prop() pdfData?            : string
    @Prop() libelleDocument?    : string
    @Prop() params?             : any
    @Prop() file_name?          : string

    Ability = Ability
    formData = new FormData()


    config = {
        toolbar: {
            toolbarViewerRight: { presentationMode: false, openFile: false, viewBookmark: false, secondaryToolbarToggle: false }
        }
    }

    source_doc: any = ''

    // Selectionne un fichier
    selectFile (e: Event) {
        if (e.target !== null) {
            const target = e.target as HTMLInputElement
            const file: File = (target.files as FileList)[0]
            this.formData.set('document', file)
        }
    }

    // Affiche le document
    showDocument (document: string) {
        this.source_doc = ''
        this.source_doc = base64ToArrayBuffer(document)
    }

    // Close popup
    closePopup () {
        this.source_doc = ''
        this.$store.commit('planning/RESET_ERROR')
        this.$emit('close')
    }

    /**
     * Téléchargement du pdf
     */
    dl_pdf () {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Téléchargement en cours ...', infosToaster)

        this.$store.dispatch('planning/getPDF', { params : this.$props.params })
            .then(response => {
                const file_name = getFileNameFromHeader(response.headers) || this.$props.file_name
                const url = URL.createObjectURL(new Blob([base64ToArrayBuffer(response.data)]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }
}
