





















































/* eslint-disable @typescript-eslint/no-unused-vars */
import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { Ability } from '@/types/Ability'
import { formatDateHoursMinutes, formatDateSinTime, getFileNameFromHeader } from '@/utils/helpers'
import  ErrorDisplay from '@/components/ErrorDisplay.vue'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupPlanningPDF             from '@/components/Plannings/PopupPlanningPDF.vue'
import { etat_seance } from '@/types/Seance'

@Component({
    components: {
        ExaGenericTable,
        ErrorDisplay,
        PopupPlanningPDF
    },
    computed: {
        ...mapState('planning', ['seances_examinateurs', 'totalRows', 'lastPage', 'total', 'loading_planning', 'currentPage', 'error_plannings', 'loadingPdf', 'pdfView']),
        ...mapGetters('examinateur', ['examinateur_select']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA'])
    }
})
export default class ConsultationExaminateur extends Vue {
    Ability = Ability
    genericfields = [
        { key: 'serie',         label: 'Série',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'date',          label: 'Date',          sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'heure',         label: 'Heure',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'centre',        label: 'Centre',        sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'salle',         label: 'Salle',         sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'equipe',        label: 'Équipe',        sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'matiere',       label: 'Matière',       sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'code',          label: 'Code',          sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'candidat',      label: 'Candidat',      sortable: false,    class: 'text-start',  type: 'text' },
        { key: 'filiere',       label: 'Filière',       sortable: false,    class: 'text-start',  type: 'text' }
    ]

    sortBy          = '';
    sortDesc        = false;
    sortDirection   = 'asc';
    filter          = '';
    filterOn        = [];
    stickyHeader    = true;

    filtres:    any         = []
    dataForTab: Array<any>  = []

    options_centres: Array<any> = []
    options_series: Array<any> = []
    options_ensembles: Array<any> = []
    options_examinateurs: Array<any> = []
    centre_id = null
    serie_select_id = null
    examinateur_select_id = null

    params_search: any = {}
    showPdf = false
    // titre_popup = this.$route.path === '/planning_suppleant' ? 'Mon planning' : 'Plannings des examinateurs'
    titre_popup = 'Plannings des examinateurs'
    pdfData                 = ''
    libelle_document: any   = ''
    paramsConfirmationImpression: any   = {}

    pdf_file_name = 'Planning-examinateur.pdf'

    @Watch('seances_examinateurs')
    watchTableau() {
        const data = this.$store.state.planning.seances_examinateurs
        this.setDataForGenericTab(data)
    }

    /** Alimentation des lignes du tableau */
    setDataForGenericTab(poData: any, isLoadMore = false)
    {
        if (!isLoadMore)
        {
            this.dataForTab = []
        }
        if (poData)
        {
            for (const result of poData)
            {
                const matiere = this.$store.state.matiere.matieres.find((m: any) => m.id === result.epreuve.matiere_id)
                const concour = this.$store.state.concour.concours.find((c: any) => c.id === result.epreuve.concour_id)
                const filiere = concour.banque_id !== null ? concour.banque.code : concour.code
                const salle = result.salle ? result.salle.name : '-'

                let candidat = ''
                if (result.candidat) {
                    candidat = result.candidat.name + ' ' + result.candidat.first_name
                    if (result.etat === etat_seance.ETAT_DEMISSIONE) {
                        candidat = '<div class="text-danger">' + candidat + ' (Démission)</div>'
                    }
                } else {
                    candidat = '<div class="text-info">Créneau disponible</div>'
                }

                const line = [
                    { label: 'serie',       item: result.serie.name,                                type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'date',        item: formatDateSinTime(result.creneau.jour),           type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'heure',       item: formatDateHoursMinutes(result.creneau.h_debut),   type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'centre',      item: result.creneau.ensemble.centre.name,              type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'salle',       item: salle,                                            type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'equipe',      item: result.creneau.ensemble.name,                     type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'matiere',     item: matiere.name,                                     type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'code',        item: result.candidat.code,                             type: 'text', typeAction: null, class: 'text-start' },
                    { label: 'candidat',    item: candidat,                                         type: 'html', typeAction: null, class: 'text-start' },
                    { label: 'filiere',     item: filiere,                                          type: 'text', typeAction: null, class: 'text-start' }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent (paParams: any): void
    {
        if (paParams && paParams[0] && paParams[1])
        {
            switch (paParams[0])
            {
                case 'onLoadPage':
                    this.loadHandler()
                    break
            }
        }
    }

    /**
     * Appel d'une nouvelle page de séances lors du scroll
     */
    loadHandler ()
    {
        Vue.set(this.params_search, 'page', (this.$store.state.planning.currentPage + 1))
        this.$store.dispatch('planning/getMoreSeances', { mode: 'examinateur', params : this.params_search })
    }

    /**
     * Exporter le planning au format excel
     */
    export_excel () {
        this.$store.commit('planning/SET_LOADING_PDF', true)
        Vue.set(this.params_search, 'excel', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'format', '')
        let fileName = ''

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours...', infosToaster)

        this.$store.dispatch('planning/getExcel', { params : this.params_search })
            .then(response => {
                const fileNameTemp = getFileNameFromHeader(response.headers)
                if (fileNameTemp) {
                    fileName = fileNameTemp
                }
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', fileName)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => {
                this.$bvToast.hide(idInfo)
            })
    }

    /**
     * Affiche le PDF du planning
     */
    show_pdf_planning () {
        Vue.set(this.params_search, 'pdf', 1)
        Vue.set(this.params_search, 'format', 'b64')
        Vue.set(this.params_search, 'excel', 0)
        this.paramsConfirmationImpression = this.params_search
        this.showPdf = true
    }

    /**
     * Fermeture du popup de PDF planning
     */
    closeModaleShowPdf () {
        this.pdfData = ''
        this.libelle_document = ''
        this.showPdf = false
    }

    // Chargement des séances
    loadSeances () {
        Vue.set(this.params_search, 'user_id', this.$store.state.auth.user.id)
        Vue.set(this.params_search, 'page', 1)
        Vue.set(this.params_search, 'pdf', 0)
        Vue.set(this.params_search, 'excel', 0)

        this.$store.dispatch('planning/getSeances', { mode: 'examinateur', params : this.params_search }).then(() => {
            this.setDataForGenericTab(this.$store.state.planning.seances_examinateurs)
        })
    }

    beforeMount () {
        this.$store.dispatch('matiere/getMatieres').then(() => {
            this.$store.dispatch('concour/getConcoursActifs').then(() => {
                this.loadSeances()
            })
        })
    }
}
