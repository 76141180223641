































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import store from '@/store/index'
import ExaGenericTable from '@exatech-group/generic-table/src/GenericTable.vue'
import PopupEditAffectationExaminateur from '@/components/AffectationExaminateurs/PopupEditAffectationExaminateur.vue'
import { Ability } from '@/types/Ability'
import { checkIcone, formatDate, formatDateDayHourDateForFileSaving, isObject } from '@/utils/helpers'
import { TypeEnsemble } from '@/types/Ensemble'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { TypePassation } from '@/types/Epreuve'
import ErrorDisplay from '@/components/ErrorDisplay.vue'
import PopUpInfoIntervenant from '@/components/PopupInfoIntervenant.vue'
import { EntityType } from '@/types/Poste'

@Component({
    components: {
        ExaGenericTable,
        PopupEditAffectationExaminateur,
        ErrorDisplay,
        PopUpInfoIntervenant,
        'font-awesome-icon': FontAwesomeIcon
    },
    computed: {
        ...mapGetters('ensembleAffectationsExaminateurs', ['loading', 'examinateursAffectations', 'examinateurAffectationSelect', 'meta', 'links', 'totalRows', 'lastPage', 'totalPage']),
        ...mapGetters('session', ['sessionSelect', 'error']),
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('serie', ['series'])
    }
})
export default class AffectationsExaminateursParEquipe extends Vue {
    // DATAS
    filtres: any = []
    dataForTab: Array<any> = []
    affectationCurrent: any = null
    Ability = Ability
    showModalEditionAffectation = false
    showModalValiderInvaliderAffectation = false
    showModalAffectationEquipeNonValidee = false
    totalAffectationsAPourvoirLocal = 0
    totalAffectationsPourvuesLocal = 0
    totalAffectationsFiltered = 0 // pour l'affichage du total réel contenu dans le tableau des affectations affiché (filtrées et non filtrées)
    activeSession: any = {}
    infosFiliereEpreuveSelect: any = {}
    ensembles_examinateurs_validated_at = null
    firstInit = true
    formatDate = formatDate
    ModeValidation = false
    ModeInvalidation = false
    ModeEdition = false
    loadingLocal = true
    planificationsLaunched = false

    saisieDisponibilitesIsOpen = false
    confirmationInvalidation = false
    validation_possible = false

    showModalInfoIntervenant = false
    user_id_temp = 0

    infoAffectationCurrent = {
        user_id: null,
        remplacant_id: null,
        affectations: []
    }

    paramsLocal = {
        page: 1,
        sort: 'ensemble.name',
        direction: 'asc'
    }

    // Ensemble des colonnes du tableau des affectations
    genericfields = [
        { key: 'etatEdit', label: '', sortable: false, class: '', type: 'action' },
        { key: 'ensemble.name', label: 'Code', sortable: true, class: 'text-center', type: 'text' },
        { key: 'ensemble.concour.name', label: 'Filière', sortable: false, class: 'text-center', type: 'text' },
        { key: 'epreuveCorrection.name', label: 'Épreuves', sortable: false, class: '', type: 'text' },
        { key: 'centre.name', label: "Centre d'oral", sortable: true, class: '', type: 'text' },
        { key: 'numero', label: 'Index examinateur', sortable: true, class: 'text-center', type: 'text' },
        { key: 'user.name', label: 'Examinateur', sortable: true, class: '', type: 'text' },
        { key: 'remplacant_name', label: 'Remplaçant', sortable: true, class: 'text-start', type: 'text' }
    ]

    // METHODS

    @Watch('examinateursAffectations')
    majDataForGenericTab() {
        // console.log('Dans Watch examinateursAffectations')
        if (!this.firstInit) {
            this.initDatas()
            this.initCompteurs()
        }
    }

    @Watch('sessionSelect')
    initDataAfterSessionActiveChange() {
        if (this.$store.getters['session/sessionSelect']) {
            this.initDatas()
        }
    }

    @Watch('meta')
    majInfosTable() {
        // On récupère le nombre total d'affectations filrées depuis les infos des Metadonnées
        if (this.$store.getters['ensembleAffectationsExaminateurs/meta']) {
            if (this.totalAffectationsFiltered !== this.$store.getters['ensembleAffectationsExaminateurs/meta'].total) {
                this.totalAffectationsFiltered = this.$store.getters['ensembleAffectationsExaminateurs/meta'].total
            }
        } else {
            this.totalAffectationsFiltered = 0
        }
    }

    @Watch('user_session_id')
    refreshInterface() {
        this.load()
    }

    isObjectLocal(value: any) {
        return isObject(value)
    }

    /**
     * Initialisation des données affecations affichées dans le tableau.
     */
    initDatas() {
        this.setDataForGenericTab(this.$store.getters['ensembleAffectationsExaminateurs/examinateursAffectations'])
    }

    /**
     * Initialisation des compteurs affectations affichés sur l'interface.
     */
    initCompteurs() {
        this.$store.dispatch('ensembleAffectationsExaminateurs/getInfoTotauxAffectationsExaminateurs')
            .then((response) => {
                this.totalAffectationsAPourvoirLocal = response.data.data.total
                this.totalAffectationsPourvuesLocal = response.data.data.affectes
            })
    }

    /**
     * Récupération sur le serveur de la session active
     */
    loadSessionActiveIfNotExists(force = false) {
        return new Promise((resolve) => {
            if (!this.$store.getters['session/sessionSelect'] || force) {
                this.$store.dispatch('session/getSession', { session_id: this.$store.getters['auth/user_session_id'] })
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des séries de la session
     */
    loadSeriesSessionActiveIfNotExists() {
        return new Promise((resolve) => {
            // on récupère systématiquement les séries de la session active
            this.$store.dispatch('serie/getSeries')
                .then(() => resolve(true))
        })
    }

    /**
     * Récupération sur le serveur des concours
     */
    loadConcoursIfNotExists() {
        return new Promise((resolve) => {
            if (this.$store.getters['concour/banques'].length === 0) {
                this.$store.dispatch('concour/getConcoursActifs')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des matieres
     */
    loadMatieresIfNotExists() {
        return new Promise((resolve) => {
            if (this.$store.getters['matiere/matieres'].length === 0) {
                this.$store.dispatch('matiere/getMatieres')
                    .then(() => resolve(true))
            } else {
                resolve(true)
            }
        })
    }

    /**
     * Récupération sur le serveur des 100 premières affectations d'examinateur si pas déja
     * présentes dans le state
     */
    loadAffectationIfNotExists() {
        return new Promise((resolve) => {
            // on récupère systématiquement la liste des affectations à jour
            this.$store.dispatch('ensembleAffectationsExaminateurs/getEnsembleAffectationsExaminateurs', this.paramsLocal)
                .then(() => resolve(true))
        })
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     */
    setDataForGenericTab(poData: any, isLoadMore = false) {
        if (!isLoadMore) {
            this.dataForTab = []
        }
        if (poData) {
            for (const result of poData) {
                const can = this.$store.getters['auth/can'](Ability.ORAL_AFEX_MANAGE)
                const droit = checkIcone(Ability.ORAL_AFEX_MANAGE, can)

                // Examinateur et Remplaçant
                let userName = ''
                let userSeries = ''
                let remplacantName = ''
                let rempacantSeries = ''
                let remplacantId = null
                let serieIndex = 1

                for (const indexAffectation in result.affectations) {
                    if (result.affectations[indexAffectation].user_id && !result.affectations[indexAffectation].remplacant_id) {
                        if (userName === '') {
                            userName = result.affectations[indexAffectation].user.name + ' ' + result.affectations[indexAffectation].user.first_name
                            userSeries = userSeries.length > 0 ? userSeries + ', S' + serieIndex : '(S' + serieIndex
                        } else {
                            userSeries = userSeries.length > 0 ? userSeries + ', S' + serieIndex : '(S' + serieIndex
                        }
                    }

                    if (result.affectations[indexAffectation].remplacant_id) {
                        if (remplacantName === '') {
                            remplacantId = result.affectations[indexAffectation].remplacant_id
                            remplacantName = result.affectations[indexAffectation].remplacant.name + ' ' + result.affectations[indexAffectation].remplacant.first_name
                            rempacantSeries = rempacantSeries.length > 0 ? rempacantSeries + ', S' + serieIndex : '(S' + serieIndex
                        } else {
                            rempacantSeries = rempacantSeries.length > 0 ? rempacantSeries + ', S' + serieIndex : '(S' + serieIndex
                        }
                    }
                    serieIndex++
                }

                // Cas où on a un remplaçant qui est affecté à toutes les séries.
                // Dans ce cas, on n'a pas récupéré le userName, donc on le récupère à la racine de l'objet, sachant que s'il y a un examinateur, le user est forcément rempli
                if (userName === '' && result.user) {
                    userName = result.user.name + ' ' + result.user.first_name
                }

                const userNameDisplayed =
                    userName === '' ? 'Non affecté' : remplacantName === '' || userSeries === '' ? userName : userName + ' ' + userSeries + ')'
                const remplacantNameDisplayed =
                    remplacantName === '' ? '-' : remplacantName + ' ' + rempacantSeries + ')'
                let userNameClass = userName === '' ? 'text-start text-danger' : 'text-start text-info item_action'
                const remplacantNameClass = remplacantName === '' ? 'text-start' : 'text-start text-info item_action'

                // Check si l'examinateur a bien les affectations poste correspondants a sa place dans l'équipe
                if (result?.user?.dossierAcademique?.poste_affectations?.length) {
                    let has_poste_ok = false
                    for (const p in result.user.dossierAcademique.poste_affectations) {
                        const pa = result.user.dossierAcademique.poste_affectations[p]
                        // Détecte si l'épreuve du poste correspond à l'épreuve de l'équipe et si le poste est bien celui d'examinateur - Cas des équipes d'interclassement
                        if (result.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT && pa.entity_id === result.epreuve_correction_id && pa.entity_type === EntityType.ET_EPREUVE_CORRECTION && pa.regroupement === 'Examinateur') {
                            has_poste_ok = true
                            userNameClass =
                                userName === '' ? 'text-start text-danger' : 'text-start text-info item_action'
                        } else if (result.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_PAR_EPREUVE || result.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_TP) {
                            // Cas pour les équipes par épreuves
                            if (result.ensemble.groupeEpreuve.epreuves.find((e: any) => e.id === pa.entity_id) && pa.entity_type === EntityType.ET_EPREUVE_CORRECTION && pa.regroupement === 'Examinateur') {
                                has_poste_ok = true
                                userNameClass = userName === '' ? 'text-start text-danger' : 'text-start text-info item_action'
                            }
                        }
                    }
                    if (!has_poste_ok) {
                        // Si aucun poste ne correspond aux attentes dans l'équipe, on affiche en rouge
                        userNameClass = userName === '' ? 'text-start text-danger' : 'text-start text-danger item_action'
                    }
                } else {
                    // Pas de poste affectations, l'examinateur ne devrait plus être dans l'équipe
                    userNameClass = userName === '' ? 'text-start text-danger' : 'text-start text-danger item_action'
                }

                // Filière et Filière-Epreuves
                let dataFiliere = ''
                let filiereEpreuveDisplayed = ''
                let is_epreuve_TP = false
                if (result.ensemble.type_ensemble === TypeEnsemble.TYPE_ENSEMBLE_INTERCLASSEMENT) {
                    dataFiliere = result.ensemble.concour.name
                    filiereEpreuveDisplayed = result.ensemble.concour.name + '-' + result.epreuveCorrection.name
                    // Check si l'épreuve est de type passation TP
                    if (result.epreuveCorrection.type_passation === TypePassation.TYPE_PASSATION_TP) {
                        is_epreuve_TP = true
                    }
                } else {
                    for (let i = 0; i < result.ensemble.groupeEpreuve.epreuves.length; i++) {
                        // Check si au moins une épreuve est de type passation TP
                        if (result.ensemble.groupeEpreuve.epreuves[i].type_passation === TypePassation.TYPE_PASSATION_TP) {
                            is_epreuve_TP = true
                        }
                        for (let j = 0; j < result.ensemble.groupeEpreuve.epreuves[i].epreuves.length; j++) {
                            const concTemp = store.getters['concour/concourById'](result.ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id)
                            if (concTemp) {
                                if (dataFiliere.length > 0) {
                                    dataFiliere += ', '
                                }
                                if (filiereEpreuveDisplayed.length > 0) {
                                    filiereEpreuveDisplayed += ', '
                                }
                                const codeConcours = store.getters['concour/concourById'](result.ensemble.groupeEpreuve.epreuves[i].epreuves[j].concour_id).code
                                dataFiliere += codeConcours
                                filiereEpreuveDisplayed += codeConcours + '-' + result.ensemble.groupeEpreuve.epreuves[i].epreuves[j].name
                            }
                        }
                    }
                }

                const line = [
                    {
                        label: droit.label,
                        item: result.id,
                        type: 'action',
                        typeAction: 'edit',
                        class: 'commons_first_action_button btn_action_ligne',
                        icon: droit.icon,
                        disabled: !droit.canValue
                    },
                    { label: '', item: result.ensemble.name, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: dataFiliere, type: 'text', typeAction: null, class: 'text-center' },
                    { label: '', item: filiereEpreuveDisplayed, type: 'text', typeAction: null, class: 'text-start' },
                    {
                        label: '',
                        item: result.centre ? result.centre.name : '-',
                        type: 'text',
                        typeAction: null,
                        class: 'text-start'
                    },
                    {
                        label: '',
                        item: is_epreuve_TP && result.numero === 1 ? 'C' : result.numero,
                        type: 'text',
                        typeAction: null,
                        class: 'text-center'
                    },
                    {
                        label: '',
                        item: userName === '' ? userNameDisplayed : result.user ? result.user.id : 0,
                        type: userName === '' ? 'text' : 'actionText',
                        typeAction: 'infoIntervenant',
                        class: userNameClass,
                        text: userNameDisplayed
                    },
                    {
                        label: '',
                        item: remplacantName === '' ? remplacantNameDisplayed : remplacantId,
                        type: remplacantName === '' ? 'text' : 'actionText',
                        typeAction: 'infoIntervenant',
                        class: remplacantNameClass,
                        text: remplacantNameDisplayed
                    }
                ]
                this.dataForTab.push(line)
            }
        }
    }

    /**
     * Formatage des datas pour l'affichage dans le tableau générique
     * this.$store.state.ensembleAffectationsExaminateurs.examinateursAffectations
     */
    setFiltersForGenericTab() {
        // Options filières
        const filieres = this.$store.getters['concour/banques']
        const options_filieres = []
        for (const f in filieres) {
            options_filieres.push({ index: filieres[f].id, name: filieres[f].name })
        }

        const matieres = this.$store.getters['matiere/matieres']
        const options_matieres = []
        for (const m in matieres) {
            options_matieres.push({ index: matieres[m].id, name: matieres[m].name })
        }

        const rootStore = this.$store.state.ensembleAffectationsExaminateurs

        this.filtres = [
            {
                libelle: 'Code',
                defautOptionlibelle: 'Rechercher un',
                model: 'ensemble.name',
                value: '',
                index: 'ensemble.name',
                datas: '',
                loading: rootStore.loading,
                options: { type: 'form', fieldsKey: 'ensemble.name', strict: true }
            },
            {
                libelle: 'Filière',
                defautOptionlibelle: 'Rechercher une',
                model: 'ensemble.concour.id',
                value: '',
                index: 'ensemble.concour.name',
                datas: options_filieres,
                loading: rootStore.loading,
                options: { type: 'deroulant', fieldsKey: 'ensemble.concour.name' }
            },
            {
                libelle: 'Épreuves',
                defautOptionlibelle: 'Rechercher une',
                model: 'matiere_id',
                value: '',
                index: 'epreuveCorrection.name',
                datas: options_matieres,
                loading: rootStore.loading,
                options: { type: 'deroulant', fieldsKey: 'epreuveCorrection.name' }
            },
            {
                libelle: "Centre d'oral",
                defautOptionlibelle: 'Rechercher un',
                model: 'centre.name',
                value: '',
                index: 'centre.name',
                datas: '',
                loading: rootStore.loading,
                options: { type: 'form', fieldsKey: 'centre.name' }
            },
            {
                libelle: "Nom d'examinateur",
                defautOptionlibelle: 'Rechercher un',
                model: 'user.name',
                value: '',
                index: 'user.name',
                datas: '',
                loading: rootStore.loading,
                options: { type: 'form', fieldsKey: 'user.name' }
            },
            {
                libelle: 'Remplaçant',
                defautOptionlibelle: 'Rechercher un',
                model: 'remplacant_name',
                value: '',
                index: 'remplacant_name',
                datas: '',
                loading: rootStore.loading,
                options: { type: 'form', fieldsKey: 'remplacant_name' }
            }
        ]
    }

    /**
     * Récupération des events du tableau
     * params[0] => l'action
     * params[1] => l'id de l'item
     */
    handleTableEvent(paParams: any): void {
        // console.log('paParams :', paParams)
        if (paParams && paParams[0] && paParams[1]) {
            const examinateursAffectations = this.$store.state.ensembleAffectationsExaminateurs.examinateursAffectations
            let affectationCurrent = null

            switch (paParams[0]) {
                case 'edit':
                    // Récupération de l'affectation by ID
                    affectationCurrent = examinateursAffectations.filter((affectation: any) => affectation.id === paParams[1])[0]
                    if (affectationCurrent) {
                        this.editAffectation(affectationCurrent)
                    }
                    break
                case 'sortHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'filterHandler':
                    this.filtreSortHandler(paParams[1])
                    break
                case 'onLoadPage':
                    this.loadHandler(paParams[1])
                    break
                case 'infoIntervenant':
                    this.infoIntervenantHandler(paParams[1])
                    break
            }
        }
    }

    infoIntervenantHandler(user_id: any) {
        this.user_id_temp = user_id
        this.showModalInfoIntervenant = true
    }

    /** Vérifie si la saisie des disponibilités est ouverte */
    checkSaisieDisponibilitesIsOpen() {
        return this.$store.getters['disponibilite/dispos_is_open'](this.activeSession)
    }

    /** Ouvre le popup de confirmation de validation des affectations */
    openValiderAffectations() {
        this.ModeValidation = true
        this.ModeInvalidation = false
        this.ModeEdition = false

        if (this.$store.getters['session/sessionSelect'].ensembles_centres_validated_at) {
            this.$store.commit('session/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
            this.ModeValidation = true
            this.ModeInvalidation = false
            this.ModeEdition = false
            this.showModalValiderInvaliderAffectation = true
        } else {
            this.showModalAffectationEquipeNonValidee = true
        }
    }

    cancelValiderInvaliderAffectations() {
        this.showModalValiderInvaliderAffectation = false
    }

    /** Ouvre le popup de confirmation d'invalidation des affectations */
    openInvaliderAffectations() {
        // On récupère la session active pour être sûr d'être à jour sur les données sensibles sur lesquelles on se base pour gérer le contexte de cette opération.
        this.loadSessionActiveIfNotExists(false).then(() => {
            this.initContextInvaliderAffectations()
        })
    }

    // initialisation des données de contexte pour lancer la popup d'invalidation des affectations.
    initContextInvaliderAffectations() {
        this.$store.commit('session/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        this.ModeValidation = false
        this.confirmationInvalidation = false
        this.ModeInvalidation = true
        this.ModeEdition = false
        this.activeSession = this.$store.getters['session/sessionSelect']
        this.ensembles_examinateurs_validated_at = this.$store.getters['session/sessionSelect'].ensembles_examinateurs_validated_at
        this.saisieDisponibilitesIsOpen = this.checkSaisieDisponibilitesIsOpen()
        this.showModalValiderInvaliderAffectation = true
    }

    /** Confirmation de la validation/invalidation des affectations */
    confirmerValiderInvaliderAffectations() {
        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }

        const params = {
            session_id: this.activeSession.id,
            payload: {
                ensembles_examinateurs_validate: this.ModeInvalidation ? 0 : 1
            }
        }

        const message_toast_enCours = `${this.ModeInvalidation ? 'Invalidation' : 'Validation'} en cours ...`
        const message_toast_success = `L'affectation des examinateurs par équipe a été ${this.ModeInvalidation ? 'invalidée' : 'validée'} !`
        this.$bvToast.toast(message_toast_enCours, infosToaster)
        this.$store.dispatch('session/updateSessionAfex', params)
            .then(() => {
                const idSucces = 't_succes_' + Math.random()
                const succesToaster = {
                    id: idSucces,
                    toaster: 'b-toaster-top-right',
                    variant: 'success',
                    noCloseButton: true,
                    fade: true,
                    autoHideDelay: 5000
                }
                this.$bvToast.toast(message_toast_success, succesToaster)

                this.loadSessionActiveIfNotExists(true)
                    .then(() => {
                        this.initCompteurs()
                        this.activeSession = this.$store.getters['session/sessionSelect']
                        this.ensembles_examinateurs_validated_at = this.$store.getters['session/sessionSelect'].ensembles_examinateurs_validated_at
                        this.ModeInvalidation = false
                        this.showModalValiderInvaliderAffectation = false
                    })
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    editAffectation(affectationCurrent: any) {
        this.ModeValidation = false
        this.ModeInvalidation = false
        this.ModeEdition = true
        if (this.$store.getters['session/sessionSelect'].ensembles_centres_validated_at) {
            this.$store.commit('ensembleAffectationsExaminateurs/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
            this.affectationCurrent = affectationCurrent
            this.infosFiliereEpreuveSelect = this.$store.getters['ensembleAffectationsExaminateurs/getFiliereEpreuve'](this.affectationCurrent.ensemble, this.affectationCurrent.epreuveCorrection)
            this.$store.commit('ensembleAffectationsExaminateurs/SET_EXAMINATEUR_AFFECTATION_SELECT', this.affectationCurrent) // init de l'affectation sélectionnée
            this.$store.commit('ensembleAffectationsExaminateurs/SET_LOADING', true) // on lance un loading le temps que l'interface d'édition capte par le watch le changement de SET_EXAMINATEUR_AFFECTATION_SELECT
            this.showModalEditionAffectation = true
        } else {
            this.showModalAffectationEquipeNonValidee = true
        }
    }

    cancelEdit() {
        this.showModalEditionAffectation = false
        this.showModalAffectationEquipeNonValidee = false
        this.affectationCurrent = null
        this.$store.commit('ensembleAffectationsExaminateurs/SET_EXAMINATEUR_AFFECTATION_SELECT', null) // reinit de l'affectation sélectionnée
        this.initDatas()
    }

    /** Exportation de la liste des affectations examinateur */
    lancerExporterAffectations() {
        this.$store.commit('ensembleAffectationsExaminateurs/SET_ERROR', null) // reset de l'erreur potentielle déjà affichée
        const currentDateFormated = formatDateDayHourDateForFileSaving(new Date(Date.now()).toISOString())

        const filenameExport = 'Exportation_Affectation_examinateurs_' + currentDateFormated + '.xlsx'

        const payload = {
            headers: {
                'Content-Disposition': 'attachment; filename=template.xlsx',
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'blob'
        }

        const idInfo = 't_info_' + Math.random()
        const infosToaster = {
            id: idInfo,
            toaster: 'b-toaster-top-right',
            variant: 'primary',
            noCloseButton: true,
            fade: true,
            noAutoHide: true
        }
        this.$bvToast.toast('Exportation en cours de création ...', infosToaster)

        this.$store.dispatch('ensembleAffectationsExaminateurs/exportationAffectationsExaminateurs', payload)
            .then((response) => {
                const file_name = filenameExport
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('Download', file_name)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            })
            .finally(() => this.$bvToast.hide(idInfo))
    }

    // Appelle une page lors du scroll
    loadHandler(params: any) {
        // console.log('Dans loadHandler')
        this.$store.dispatch('ensembleAffectationsExaminateurs/getMoreEnsembleAffectationsExaminateurs', params)
            .then(() => this.initDatas())
    }

    filtreSortHandler(params: any) {
        // console.log('Dans filtreSortHandler')
        if (JSON.stringify(params) !== JSON.stringify(this.paramsLocal)) {
            this.paramsLocal = params
            this.$store.dispatch('ensembleAffectationsExaminateurs/getEnsembleAffectationsExaminateurs', params)
                .then(() => this.initDatas())
        }
    }

    // Envoie sur la page du trombinoscope
    goToTrombinoscope() {
        this.$router.push('/trombinoscope_examinateurs_g')
    }

    load() {
        // Si la session active n'est pas chargée, alors on la récupère
        this.loadSessionActiveIfNotExists(false)
            .then(() => {
                this.activeSession = this.$store.getters['session/sessionSelect']
                this.ensembles_examinateurs_validated_at = this.$store.getters['session/sessionSelect'].ensembles_examinateurs_validated_at
                // Récupération des séries de la session active
                this.loadSeriesSessionActiveIfNotExists().then(() => {
                    this.planificationsLaunched = this.$store.getters['serie/isplanificationsLaunched'] // check si la planification a été lancée
                    // Si aucun concours n'as été chargé, alors on les récupère
                    this.loadConcoursIfNotExists().then(() => {
                        // Si les matières ne sont pas chargées, alors on les récupère
                        this.loadMatieresIfNotExists().then(() => {
                            // Si les affectations ne sont pas chargées, alors on les récupère
                            this.loadAffectationIfNotExists().then(() => {
                                this.setFiltersForGenericTab()
                                this.loadingLocal = false

                                // Validation / Invalidation uniquement dans le contexte des oraux
                                if (this.$route.path === '/affectation_examinateurs') {
                                    this.validation_possible = true
                                }
                            })
                        })
                    })
                })
            })

        this.firstInit = false
    }

    /**
     * Au montage du composant, on charge les différentes données dans le store si cela est nécéssaire
     */
    mounted() {
        this.load()
    }
}
