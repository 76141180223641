


























































































































import Vue                                                  from 'vue'
import { formatDateSinTime, dateNow, formatDateYYYYMMDD }                                       from '@/utils/helpers'
import {  Watch, Component }                                from 'vue-property-decorator'
import { mapGetters, mapState }                                       from 'vuex'
import { Ability }                                          from '@/types/Ability'
import AffichageCandidatsIncompatibles                      from '@/components/GestionIncompatibles/AffichageCandidatsIncompatibles.vue'
import RechercheParEtablissement                            from '@/components/GestionIncompatibles/RechercheParEtablissement.vue'
import RechercheParCandidat                                 from '@/components/GestionIncompatibles/RechercheParCandidat.vue'
import MessageGeneralIntervenant                            from '@/components/MessageGeneralIntervenant.vue'
import { MessageIndicationType } from '@/types/MessageIndicationType'
import  ErrorDisplay                            from '@/components/ErrorDisplay.vue'



@Component({
    computed: {
        ...mapGetters('auth', ['authUser', 'can', 'cannot', 'isA', 'isNotA', 'user_session_id']),
        ...mapState('auth', ['user', 'authUser', 'user_session_id']),
        ...mapGetters('candidatIncompatible', [
            'listIsLoading',
            'listeCandidatsIncompatiblesGroupee',
            'listIsLoading',
            'selectedExaminateur',
            'get_submitted_at',
            'get_validated_at',
            'incompatibleIsRejected',
            'error'
        ]),
        ...mapGetters('session', ['sessions', 'sessionSelect'])

    },
    components: {
        'affichage-resultat': AffichageCandidatsIncompatibles,
        RechercheParEtablissement,
        RechercheParCandidat,
        MessageGeneralIntervenant,
        ErrorDisplay
    }
})
export default class GestionIncompatiblesExaminateur extends Vue {
    ORAL_PREPA_MANAGE                       = Ability.ORAL_PREPA_MANAGE
    ORAL_PREPA_VIEW                         = Ability.ORAL_PREPA_VIEW
    isLoading                               = this.$store.getters['candidatIncompatible/listIsLoading']
    examinateurSide                         = true
    candidatsGroupeeParEtablissements       = []
    examinateurSelectionne                  = this.$store.getters['auth/authUser']
    /* Mode liste ou edition (ajoute l'icone trash) */
    editable                                = true
    afficherButtonsRechercher               = true
    /* Utiliser pour changer la valeur footer et l'affichage de la modale */
    componentView   = 'affichage_candidats_incompatibles'
    /* Controle si soumission, utilisé pour passer en viewOnly */
    isSubmitted     = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
    isValidated     = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
    isRejected      = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
    comment_reject  = this.$store.state.candidatIncompatible.comment_reject
    submitted_at    = this.$store.getters['candidatIncompatible/get_submitted_at']
    validated_at    = this.$store.getters['candidatIncompatible/get_validated_at']
    activeSession: any   = {}
    sessionIsOpenBoolean = true
    todayDate            = Date.now()
    showDismissibleAlert = true
    MessageIndicationType = MessageIndicationType

    @Watch('listeCandidatsIncompatiblesGroupee')
    getListeCandidatsIncompatibles() {
        this.candidatsGroupeeParEtablissements = this.$store.getters['candidatIncompatible/listeCandidatsIncompatiblesGroupee']
    }

    @Watch('listIsLoading')
    watchLoading() {
        this.isLoading          = this.$store.getters['candidatIncompatible/listIsLoading']
    }

    @Watch('get_submitted_at')
    watchSubmit() {
        this.isSubmitted        = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated        = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected         = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.submitted_at       = this.$store.getters['candidatIncompatible/get_submitted_at']
        this.comment_reject     = this.$store.state.candidatIncompatible.comment_reject
    }

    @Watch('get_validated_at')
    watchValidated() {
        this.isSubmitted        = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated        = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected         = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.comment_reject     = this.$store.state.candidatIncompatible.comment_reject
        this.validated_at       = this.$store.getters['candidatIncompatible/get_validated_at']
    }

    @Watch('incompatibleIsRejected')
    watchRejected() {
        this.isSubmitted        = this.$store.getters['candidatIncompatible/incompatibleIsSubmitted']
        this.isValidated        = this.$store.getters['candidatIncompatible/incompatibleIsValidated']
        this.isRejected         = this.$store.getters['candidatIncompatible/incompatibleIsRejected']
        this.validated_at       = this.$store.getters['candidatIncompatible/get_validated_at']
        this.comment_reject     = this.$store.state.candidatIncompatible.comment_reject
    }

    @Watch('sessions', { immediate: true })
    watchSession() {
        this.activeSession      = this.$store.getters['session/sessions'].find((s: any) => s.id === this.$store.getters['auth/user_session_id'])
        this.sessionIsOpenBoolean = this.sessionIsOpen()
    }


    @Watch('user_session_id')
    refreshInterface () {
        this.load()
    }

    /** recuperation des candidats incompatibles  */
    getIncompatible() {
        this.$store.state.candidatIncompatible.error = null
        this.examinateurSelectionne = this.$store.getters['auth/authUser']
        this.$store.dispatch('candidatIncompatible/getCandidatsIncompatibles', this.examinateurSelectionne.id)
    }

    /** utiliser pour les changements de la vue des composants (recoit le div class du composant a afficher)  */
    changeComponentView(classComponentName: string) {
        this.$store.state.candidatIncompatible.error = null
        this.componentView = classComponentName
    }

    /** Retourne une classe css selon l'état de validation des infos */
    getClassBarreBoutons () {
        let class_css = 'barre_default'
        if (this.$store.getters['candidatIncompatible/incompatibleIsSubmitted'] && !this.$store.getters['candidatIncompatible/incompatibleIsValidated']) {
            class_css = 'barre_soumis'
        } else if (this.$store.getters['candidatIncompatible/incompatibleIsSubmitted'] && this.$store.getters['candidatIncompatible/incompatibleIsValidated']) {
            class_css = 'barre_valide'
        } else if (this.$store.getters['candidatIncompatible/incompatibleIsRejected']) {
            class_css = 'barre_rejet'
        }
        return class_css
    }

    /* Au clic sur soumission, envois les informations a vuex et mets en mode view only */
    submitIncompatible()
    {
        this.$store.state.candidatIncompatible.error = null
        const userId = this.$store.getters['auth/authUser']
        const params = {
            user_id: userId.id,
            incompatibilites_submit : 1
        }
        this.$store.dispatch('candidatIncompatible/SUBMIT_INCOMP', params)
    }

    /** Formattage des dates */
    formatTheDate (date: Date) {
        return formatDateSinTime(date)
    }

    /** contrôle si la date du jour et est bien entre les deux dates d'ouverture de la saisie des incompat . return  */
    sessionIsOpen() {
        const todayDate       = dateNow()

        if (this.activeSession && this.activeSession.incompatibilites_start_at === null && this.activeSession.incompatibilites_end_at === null) {
            return false
        }
        if (this.activeSession && this.activeSession.incompatibilites_start_at && this.activeSession.incompatibilites_end_at && todayDate)
        {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(this.activeSession.incompatibilites_end_at)
            return this.activeSession.incompatibilites_start_at <= todayDate && todayDateFormatted <= sessionEndDateFormatted
        } else if (this.activeSession && this.activeSession.incompatibilites_start_at === null && this.activeSession.incompatibilites_end_at && todayDate)
        {
            const todayDateFormatted = formatDateYYYYMMDD(todayDate)
            const sessionEndDateFormatted = formatDateYYYYMMDD(this.activeSession.incompatibilites_end_at)
            return todayDateFormatted <= sessionEndDateFormatted
        }
        else {
            return false
        }
    }

    load () {
        this.$store.state.candidatIncompatible.error = null
        this.$store.dispatch('auth/getAuthUser')
        const userId = this.$store.getters['auth/authUser']
        const params = {
            user_id: userId.id
        }
        this.$store.dispatch('candidatIncompatible/GET_STATE_SUBMISSION', params)
        this.getIncompatible()
    }

    beforeMount  () {
        if (this.$store.getters['auth/user_session_id'] !== null) {
            this.load()
            this.$store.state.candidatIncompatible.error = null
        }
    }
}
